import React from "react";
import styles from  './navbar.module.css'
import {Link} from "react-router-dom";


const Navbar = ()=>{
    return(
        <div className={styles.nav_container}>
            <div className={styles.brand_name}>
              <Link to='/'>Anna Baroyan</Link>
            </div>
            <div className={styles.menu_container}>
                <Link to='/'>Home</Link>
                <Link to='/about'>About</Link>
                <Link to='/podcast'>Podcast</Link>
            </div>
        </div>
    )
}
export default Navbar