import './App.css';
import {Route, Routes} from "react-router";
import Home from "./container/Home/home";
import About from "./container/About/about";
import Podcast from "./container/Podcast/podcast";

function App() {
  return (
  <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/podcast' element={<Podcast/>}/>
  </Routes>
  );
}
export default App;
