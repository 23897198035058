import React  from "react";
import styles from './home.module.css'
import image from '../../assets/images/Untitled.png'
import Slide from "./slide";
import Videos from "./videos";
import About from "./about";
import Books from "./books";



const Home = ()=>{
    return(
        <div className={styles.container}>
        <div className={styles.main_container}>
            <div className={styles.image_container}>
                <img src={image} className={styles.image}/>
            </div>
            <div className={styles.text_container}>
                <div className={styles.name}>
                    <span className={styles.name_txt}>Anna Baroyan</span>
                    <span className={styles.status_txt}>HRM Services, Executive Coaching </span>

                </div>
                <div className={styles.status}>
                    <span>Keep growing, Keep learning </span>
                </div>
            </div>
        </div>
            <Slide/>
            <About/>
            <Books/>
            <Videos/>
        </div>
    )
}
export default Home