import React from "react";
import styles from './footer.module.css'
import {RiLinkedinFill} from 'react-icons/ri'
import {RiFacebookFill} from 'react-icons/ri'
import {RiInstagramFill} from 'react-icons/ri'
import {RiPhoneLine} from 'react-icons/ri'
import {RiMailLine} from 'react-icons/ri'


const Footer = ()=>{
    return(
        <div className={styles.footer_container}>
            <div className={styles.text}>
              <span className={styles.name}>Anna Baroyan</span>
              <span className={styles.status}>HRM Services, Executive Coaching </span>
            </div>
            <div className={styles.text1}>
                <span className={styles.status}>Contact Me</span>
                <div>
                   <div className={styles.div}>
                       <RiMailLine  size='40px' className={styles.icon}/>
                       <span>info@annabaroyan.com <br/>coach@annabaroyan.com</span>

                   </div>
                    {/* <div className={styles.div}>
                        <RiPhoneLine  size='40px' style={{marginTop:'5px'}} className={styles.icon}/>
                        <span>+374 98 933 420</span>
                    </div> */}
                </div>
            </div>
            <div className={styles.text1}>
                <span className={styles.status}>Follow Me</span>
                <div>
                    <a href='https://www.linkedin.com/in/annabaroyan/' target='_blank'><RiLinkedinFill size='40px' className={styles.icon}/></a>
                    <a href='https://www.facebook.com/annabaroyancoach' target='_blank'><RiFacebookFill size='40px'className={styles.icon}/></a>
                    <a href='https://www.instagram.com/anna_baroyan_coach/' target='_blank'> <RiInstagramFill size='40px' className={styles.icon}/></a>
                </div>
            </div>
        </div>
    )
}
export  default Footer