import React from "react";
import styles from './podcast.module.css'
import image from '../../assets/images/IMGC0282.jpg'
import mp3 from  '../../assets/images/1-2.mp3'
import mp3_2 from '../../assets/images/1-3.mp3'
import mp3_3 from '../../assets/images/2-1.mp3'
import mp3_4 from '../../assets/images/2-2.mp3'
import mp3_5 from '../../assets/images/2-3.mp3'
import mp3_6 from  '../../assets/images/3-1.mp3'
import mp3_7 from '../../assets/images/3-2.mp3'
import mp3_8 from '../../assets/images/3-3.mp3'

const Podcast = ()=>{
    const audio = [mp3,mp3_2,mp3_3,mp3_4,mp3_5,mp3_6,mp3_7,mp3_8]
    return(
    <>
        <div className={styles.main_container}>
            <img src={image} className={styles.image} alt=''/>
            <div className={styles.text_container}>
               <span className={styles.text}>Anna Baroyan</span>
                <span className={styles.txt}>
                A powerful mix of interviews with the biggest thought leaders and experts in the world, plus mic-dropping wisdom from Anna.
                </span>
            </div>
        </div>
        <div className={styles.audio_container}>
            {audio.map((element,index)=>{
               return   <div className={styles.audio_cont}>
                <span className={styles.title}>Episode {index + 1}</span>
                <audio controls src={element} className={styles.audio}> </audio>
                </div>
            })}
        </div>

    </>
    )
}

export default Podcast