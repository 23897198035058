import React from "react";
import styles from './about.module.css'
import image from '../../assets/images/Untitled.png';
import logo1 from '../../assets/images/nagashyan.png'
import logo2 from '../../assets/images/ifix.jpeg'
import logo3 from '../../assets/images/m&d.jpeg'
import logo4 from '../../assets/images/Locator.jpg'
import logo5 from '../../assets/images/digitaledge.png'
import logo6 from '../../assets/images/BCB.jpg'
import logo7 from '../../assets/images/4u.am.jpg'
import logo8 from '../../assets/images/mutable.png'
import logo9 from '../../assets/images/icare.jpg'
import logo10 from '../../assets/images/baron.jpg'
import Carousel from 'react-grid-carousel'

const About = ()=>{
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 5
        }
    };
    return(
        <>
            <div className={styles.container}>
                <div className={styles.text}>
                    <span className={styles.title}>ABOUT ME</span>
                    <div className={styles.txt}>
                        As a Human Resource Management expert and Business trainer and coach I have accompanied many challenging and practical processes. My experience as a former head of the human resource management department, project manager, and coach gave me the huge experience to establish and run my business and training (coaching, human resource management, emotional intelligence, business ethics, and sustainability).
                        For more than 7 years I worked in human resource management areas. My main mission in the organizations was to establish an HRM department and delivering all stages, creating HRM strategic plan, and being at the level of HR business partner. My methodological plans brought organizations to the changes of organization culture, effective business planning, personnel development, and sustainability.
                        It is already 9 years that I am interested in coaching, delivering coaching sessions to talents, specialists, and managers. Currently, I am an internationally certified coach and hold my ICF ACSTH.
                        For me, coaching is the driving tool that inspires me to grow constantly and inspires others to grow as well. My inspiration to work with organizations by delivering training, coaching, and providing them organization development services bought me to one of the challenging ideas. Being a Ph.D. researcher at the National Academy of Science I have started the research on the topic of “Problems of using coaching in the development of organizations in RA”.
                        My Experience as a trainer
                        Since 2017 I have been created and delivered HR management, organization development, and coaching training. So far I have delivered +20 trainings for individuals and teams with medium size group (up to 20 participants).
                        <br/>
                        Training topics
                        <ol className={styles.list}>
                            <li>“Human resource management; methodology”</li>
                            <li>“Human resource management; recruitment”</li>
                            <li>“Business Ethics and sustainability”</li>
                            <li>“Emotional intelligence in the workplace”</li>
                            <li>“Coaching in Workplace”</li>
                            <li>“Coaching for HR managers”</li>
                            <li>“Coaching competencies”</li>
                            <li>“Do you want to become a coach?”</li>
                        </ol>
                    </div>
                </div>
            <div className={styles.img}>
                <img src={image} className={styles.image}/>
            </div>
            </div>

            <div className={styles.static}>
                <div className={styles.div}>
                    <span className={styles.number}>+100</span>
                    <span>Students</span>
                </div>
                <div className={styles.div}>
                    <span className={styles.number}>+120</span>
                    <span>Coaching sessions</span>
                </div>
                <div className={styles.div}>
                    <span className={styles.number}>+20</span>
                    <span>Trainings delivered</span>
                </div>
            </div>
            <>
            <div className={styles.header}> Cooperation</div>
                <div className={styles.carousel}>
                <Carousel cols={1} rows={1} gap={10} loop>
                    {[logo9,logo2,logo3,logo6,logo8,logo4,logo7,logo5,logo1,logo10].map((el)=>{
                        return  <Carousel.Item>
                            <div className={styles.logo_container}>
                                <img  src={el} className={styles.logo}  alt='logo'/>
                            </div>
                        </Carousel.Item>
                    })}
                </Carousel>
                </div>
            </>
        </>

    )
}
export default About