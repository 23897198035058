import React from "react";
import styles from './home.module.css'
import armCoaching from '../../assets/images/Armcoaching_logo.svg'
import hirenet from '../../assets/images/hirenet_logo.svg'
import soroban from '../../assets/images/sorobonlab_logo.svg'

const Slide = ()=>{
    return(
        <div className={styles.slide_container}>
            <div className={styles.cnt1}> <img src={hirenet} alt=''/></div>
            <div className={styles.cnt2}> <img src={armCoaching} alt=''/></div>
            <div className={styles.cnt}> <img src={soroban} alt=''/> </div>
        </div>
    )
}
export default Slide