import React from "react";
import styles from './home.module.css'
import ebook1 from '../../assets/images/ebook_1.jpg'
import ebook2 from '../../assets/images/ebook_3.jpg'
import book3 from '../../assets/images/ashxatanqayin dzernark verjnakan-01.jpg'

const Books = ()=>{
    return(
        <div className={styles.books_container}>
            <div className={styles.title}>
                <span> BOOKS </span>
            </div>
            <div className={styles.books}>
                <div><img src={ebook1}/></div>
                <div><img src={ebook2}/></div>
                <div><img src={book3}/></div>
            </div>
        </div>
    )
}

export  default Books