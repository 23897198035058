import React from "react";
import styles from './home.module.css'


const Videos = ()=>{
    return(
        <div className={styles.videos_container}>
            <div className={styles.title}>
                <span>FEATURED VIDEOS</span>
            </div>
            <div className={styles.videos}>
                <div>

                    <iframe  src="https://www.youtube.com/embed/HRliKv5xP_I"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                    <iframe  src="https://www.youtube.com/embed/MnTlGOS7P_M"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen> </iframe>
                    <iframe width="450" height="315" src="https://www.youtube.com/embed/72fsznVb2zg"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen> </iframe>
                </div>
                <div>
                    <iframe  src="https://www.youtube.com/embed/NzxRCGcTr5I"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen> </iframe>
                    <iframe width="450" height="205" src="https://www.youtube.com/embed/B6Ta6Eq8NWU"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen> </iframe>

                    <iframe  src="https://www.youtube.com/embed/iTmuJDsN4-Y"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen> </iframe>
                </div>
                <div>
                    <iframe width="450" height="205" src="https://www.youtube.com/embed/XbuEw44eD1o"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    <iframe width="450" height="205" src="https://www.youtube.com/embed/6MbFFChu3Mg"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>

                    <iframe width="450" height="205" src="https://www.youtube.com/embed/aoHhRpP1P2k"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
}
export default Videos