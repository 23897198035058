import React from "react";
import  styles from './home.module.css'
import image2 from '../../assets/images/IMGC0219.jpg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const About = ()=>{
    return(
        <div className={styles.about_container}>
            <div className={styles.text}>
                <span className={styles.title}>ABOUT ME</span>
                <span className={styles.txt}>
                 Expert Human Resource Management specialist with extensive Coaching experience. With a deep knowledge of the Labor Code of Armenia, I provide the full spectrum of HR management for your company. Having a proven record of coaching and mentorship experience I organize customized trainings for your whole personnel. Working experience with stakeholders helps me quickly understand client’s needs and generate an effective solution for each particular case. Being proactive and problem solver in nature, I do identify and set clear objectives and goals for individuals and teams, thus increasing their productivity and communication dramatically. Looking for interesting and challenging projects in HR Management and Business coaching.
                </span>
            </div>
            <div className={styles.about_img}>
                <img  src={image2}/>
            </div>
        </div>
    )
}
export default About